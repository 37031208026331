import React from 'react';
import styles from './Footer.module.scss';
import txtlogo from '../../assets/images/logo_olivya_txt.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerColumn}>
        <div className={styles.logo}>
        <img src={txtlogo} alt='Olivya logo'/>
      </div>
      </div>
      <div className={styles.footerColumn}>
  <p><strong>About Us</strong></p>
  <ul>
    <li><a href="/">Home</a></li>
    <li><a href="/about">About</a></li>
    <li><a href="/contact">Contact</a></li>
  </ul>
</div>
<div className={styles.footerColumn}>
  <p><strong>Services</strong></p>
  <ul>
    <li><a href="/ai-consulting">AI Consulting</a></li>
    <li><a href="/ai-software">AI Software</a></li>
    <li><a href="https://voice.olivya.io">AI Voice Agent</a></li>
  </ul>
</div>
<div className={styles.footerColumn}>
  <p><strong>Support</strong></p>
  <ul>
    <li><a href="/privacy">Privacy Policy</a></li>
    <li><a href="/terms">Terms of Use</a></li>
    <li><a href="https://flow.olivya.io">Flow</a></li>
  </ul>
</div>
      </div>
      <div className={styles.footerBottomLine}>
        <p>2023 Olivya Limited. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
