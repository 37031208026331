import React from 'react'
import styles from './Payment.module.scss'
import { useNavigate } from 'react-router-dom';
import ImageSection from '../../Components/Sections/ImageSection';
import Process from '../../Components/Sections/Process';
import AiPaymentImg from '../../assets/images/ai-payment-img.png'
import smartContractsIcon from '../../assets/images/smart-contract.png'
import partnershipsIcon from '../../assets/images/partnership.png'
import TitleSection from '../../Components/Sections/TitleSection';


function Payments() {
  const check = [
    { title: 'AI-Powered Intuitive Platform', 
    text: 'Set up future payments with ease, no technical expertise required. Setting up a complex payment is as easy as plain language. Our platform will transform it into code.' },
    { title: 'Centralised and trusted party', 
    text: "Our processes does away with the risk of not getting paid. We hold funds in escrow and make them abide by strict rule of the road. " },
    { title: 'Fiat Currency Transactions', 
    text: 'We specialise in smart payments using fiat currencies, ensuring broad accessibility, easy adoption and compliance.' },
  ];

  const navigate = useNavigate();

  const handleButtonClickNavigation = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };


  return (
    <div className={styles.payments}>
  
<TitleSection
      title="Revolutionise Your Transactions with Olivya AI Payments"
      subtitle1="Seamless, Smart, Secure: The Future of Payments"
      subtitle2="Olivya's Payments platform redefines the transaction experience, offering smart, intuitive, and flexible payment solutions that cater to the dynamic needs of your business."
bgColor='#35736B'
txtColor='#FAFDFB'


/>
  
<div className={styles.offering}>
  <h2>What We Offer</h2>
  <div className={styles.columnContent}>
    <div className={styles.column}>
      <div className={styles.card}>
  
        <div className={styles.paymentSection}>
        <div className={styles.centerAlign}> {/* Add this div */}
    <img src={smartContractsIcon} alt="Smart Contracts" className={styles.icon} />
    <p><strong>Smart Contracts</strong></p>
  </div>
          <p>Self-executing contracts with the terms of the agreement directly written into code. The code controls the execution, and transactions are trackable.</p>
          <p>Smart contracts permit trusted transactions and agreements to be carried out among disparate parties.</p>

        </div>
        <div className={styles.paymentSection}>
          <p><strong>Example</strong></p>
          <p>You agree with John, a sales team-member that they will be paid 1% of all revenues they generate.</p>
          <p>You input this formula into Olivya for it to track John’s sales and pay him 1% of this figure at the end of the month.</p>
          <p>Olivya executes the agreement and initiates the payment as and when instructed. </p>
          <p><strong>This method is great for any metrics based incentive, think online clicks, hours worked, leads generated etc...</strong></p>


        </div>
      </div>
    </div>
    <div className={styles.divider}></div> 
    <div className={styles.column}>
      <div className={styles.card}>
        <div className={styles.paymentSection}>
        <div className={styles.centerAlign}> {/* Add this div */}
    <img src={partnershipsIcon} alt="Partnerships" className={styles.icon} />
    <p><strong>Partnerships</strong></p>
  </div>
               <p>Olivya is the perfect medium for your company partnerships. The platform will communicate with various parties’ software and payments can be made to abide by the rules of the road that you set with your partners.</p>
        </div>
        <div className={styles.paymentSection}>
          <p className={styles.secondEx}><strong>Example</strong></p>
          <p>You are a telecom operator and jointly use telecommunication towers with other operators. </p>
          <p>The capital requirements depend on your utilisation of the capacity.</p>
          <p>Olivya can track and enforce payments according to the agreement enter into by all partners.</p>
          <p><strong>This method removes the administrative burden and the need to trust your partners ability to pay. Think revenue sharing, cost sharing and incentives. </strong></p>


        </div>
      </div>
    </div>
  </div>
</div>

      <Process 
          title="Advantages of Olivya Payments"
          subtitle="Follow these steps to complete the process"
          checks={check}
          bgColor='#35736B'
          txtColor='#FAFDFB'
          isTimeline={false}
      />

<ImageSection 
      title="Join the Payment Revolution"
      subtitle1="Transform and secure your payment processes with the intelligence of Olivya AI Payments."
      btnLabel="Get Started"
      bgColor='#FAFDFB'
      txtColor='#1F3F3C'
      isIcon={true}
      bgImage={AiPaymentImg}
      onClickButton={handleButtonClickNavigation}
      btnColor='#93A6A4'
      />

      
    </div>
  )
}

export default Payments