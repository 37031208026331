import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './ImageSection.module.scss';
import CustomButton from '../Button/CustomButton';
import logo from '../../assets/svg/olivya-logo.svg'; 

const ImageSection = ({
  title,
  subtext,
  subtitle1,
  subtitle2,
  quote,
  name,
  bgImage,
  bgColor,
  headerTxtColor,
  txtColor,
  btnLabel,
  btnColor,
  imageRight,
  listItems, 
  isList,
  isIcon,
  isbglogo,
  onClickButton
}) => {
  const sectionStyles = {
    backgroundColor: bgColor,
    color: txtColor,
  };

  const imageVariant = {
    offscreen: { opacity: 0, x: imageRight ? 50 : -50 },
    onscreen: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const textVariant = {
    offscreen: { opacity: 0, y: 20 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } },
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const flexDirection = isMobileView ? 'column' : (imageRight ? 'row-reverse' : 'row');


  return (
    <motion.section 
      className={styles.imageSectionContainer} 
      style={{ ...sectionStyles, flexDirection }}
      initial="offscreen"
      whileInView="onscreen"
    >
      <motion.div 
        className={styles.imageContainer} 
        variants={imageVariant}
        initial="offscreen"
        whileInView="onscreen"
      >
        <img 
          src={bgImage} 
          alt='bg-image' 
          className={isIcon ? styles.iconImage : ''} 
        />
      </motion.div>

      

      <motion.div 
        className={styles.contentContainer} 
        variants={textVariant}
        initial="offscreen"
        whileInView="onscreen"
      >
        <h3 style={{color: headerTxtColor}}>{title}</h3>
        {subtext && <p><strong>{subtext}</strong></p>}
        {isList && listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index} className={styles.listItem}>
                <div className={styles.iconTextContainer}>
                  <img src={item.icon} alt='List icon' className={styles.listIcon} />
                  <div className={styles.listText}>{renderListItemText(item.text)}</div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {!isList && (
          <>
            {subtitle1 && <p>{subtitle1}</p>}
            {subtitle2 && <p>{subtitle2}</p>}
            {quote && <p className={styles.quotes}>{quote}</p>}
            {name && <p>{name}</p>}
          </>
        )}
        {btnLabel && <CustomButton label={btnLabel} bgColor={btnColor} onClick={onClickButton} />}
      </motion.div>
    
      {isbglogo && (
        <div className={imageRight ? styles.logoLeft :  styles.logoRight}>
          <img src={logo} alt="Logo" />
        </div>
      )}

    </motion.section>
    
  );
}

function renderListItemText(text) {
  const splitIndex = text.indexOf(':');
  if (splitIndex !== -1) {
    const boldText = text.substring(0, splitIndex + 1); // Include semicolon in bold text
    const regularText = text.substring(splitIndex + 1);
    return (
      <>
        <strong>{boldText.trim()}</strong> {regularText.trim()}
      </>
    );
  }
  return text;
}

export default ImageSection;
