import React from 'react'
import styles from './About.module.scss';
import ImageSection from '../../Components/Sections/ImageSection';
import IconSection from '../../Components/Sections/IconSection';
import Process from '../../Components/Sections/Process';
import WorkspaceImg from '../../assets/images/workspace.png'
import FullImage from '../../Components/Sections/FullImage';
import Icon1 from '../../assets/images/empowerment.png'
import Icon2 from '../../assets/images/equity.png'
import Icon3 from '../../assets/images/excellence.png'
import logo from '../../assets/svg/olivya-logo.svg'; 
import TitleSection from '../../Components/Sections/TitleSection';

function About() {
  const missionColumn = [
    {
      icon: Icon1,
      iconTitle: 'Empowerment',
      iconDescription: "We're committed to a fairer society where technology is accessible to all."
    },
    {
      icon: Icon2,
      iconTitle: 'Equity',
      iconDescription: 'Improve efficiency by identifying workflow-improving AI applications'
    },
    {
      icon: Icon3,
      iconTitle: 'Excellence',
      iconDescription: "Our pursuit of excellence drives us to deliver only the best, most innovative solutions."
    },
  ];

  return (
    <div className={styles.about}>
      <TitleSection
      title="Focusing the Workplace Towards Human Prosperity "
      subtitle1="We believe in a future where technology is a powerful, intuitive tool that enables humans to use their intelligence, creativity and ingenuity towards purposeful endeavours. "
      bgColor='#35736B'
txtColor='#FAFDFB'
      />

       <div className={styles.vision}>
        <h1>Our Vision</h1>
        <div className={styles.columnContent}>
          <div className={styles.column}>
            <p><strong>Harmonising Technology with Humanity</strong></p>
            <p>Technology should serve as a seamless extension of human capability, not an alien presence that intimidates or confounds.</p>
            <p>We are advocates for a technology that amplifies our natural skills, allowing everyone to engage in endeavors that are both exciting and meaningful.</p>
            <p>It is our belief that when technology is intuitive, it empowers individuals to unleash their creativity, ingenuity, and spontaneity.</p>
            <p>By crafting tools that feel like a natural extension of ourselves, we can foster a world where technology uplifts and complements the human experience.</p>
          </div>
          <div className={styles.column}> 
            <p><strong>A Future Shaped by Inclusive Innovation</strong></p>
            <p>The rise of technology has often been met with concerns about job security and societal impact.</p>
            <p>However, we see technology as a force for collective betterment, transforming routine tasks into intuitive operations and creating space for greater creativity and personal expression.</p>
            <p>We trust in humanity's resilience and adaptability to embrace a new era where technology enhances our intellectual and intuitive capabilities.</p>
            <p>Envisioning a future where work is free from drudgery, we are committed to building a world where equitable access to technological advancements contributes to a more just and prosperous society for all.</p>
          </div>
          
        </div>
       
      </div>
      
      
      <IconSection
  title="Our Mission & Values"
  subtitle1='Our mission is to forge a world where simplicity reigns, achieved through the seamless synergy of businesses, individuals, and technology. We are dedicated to fostering collaborations and partnerships that transcend traditional boundaries, empowering minds and nurturing innovation.'
  subtitle2='Our commitment is to catalyse progress by making the complex intuitive, the cumbersome effortless, and the ordinary extraordinary, empowering each individual to bring their visionary ideas to life.'
  columns={missionColumn}
  bgColor="#35736B"
  txtColor='white'
  headingtxtColor="white"
/>
      
    </div>
  )
}

export default About