import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './AISoftware.module.scss';
import ImageSection from '../../Components/Sections/ImageSection';
import FullImage from '../../Components/Sections/FullImage'
import CraftingImage from '../../assets/images/crafting-ai-vision.png'
import Process from '../../Components/Sections/Process';
import Card from '../../Components/Card/Card'
import AiSoftwareBG from '../../assets/images/ai-software-bg.png';
import FutureAccelerated from '../../assets/images/future-accelerated.png';
import Icon1 from '../../assets/images/exclude_icon.png';
import Icon2 from '../../assets/images/chart_icon.png';
import Icon3 from '../../assets/images/contactless_payment_icon.png';
import TitleSection from '../../Components/Sections/TitleSection';
import CTABg from '../../assets/images/cta_bg.png';


function AiSoftware() {
  const check = [
    { title: 'Business Process Optimisation', 
    text: 'Our software are designed to simplify complex workflows, automate repetitive tasks and help deal with things like customer service, quality control and supply chain control. ' },
    { title: 'Productivity Enhancement', 
    text: "By leveraging generative AI technology, our software can boost your team's productivity on any type of tasks or content such as media, renderings, text and  promotional content." },
    { title: 'Engagement and Experience', 
    text: 'We enhance the experience of both your employees and clients by creating intuitive interfaces and smart features that respond to user needs and behaviours, fostering higher engagement and satisfaction.' },
  ];


  const navigate = useNavigate();

  const handleButtonClickNavigation = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.aiSoftware}>
      <TitleSection

      title="We are in the business of harnessing AI to reshape enterprise operations "
      subtitle1="Our mission is to engineer mission-specific software solutions that not only streamline your business operations but also amplify productivity and elevate user engagement."
      txtColor='#FAFDFB'
      
      />
       <ImageSection
       title="Crafting Your AI Vision"
       subtitle1="We believe that the journey from conception to realisation is critical. "
       subtitle2="Our team works closely with you from the outset to understand your vision, objectives, and challenges. We then translate this understanding into bespoke software that embodies your business ethos while harnessing the transformative power of AI."
       bgColor='#1F3F3C'
       txtColor='#FAFDFB'
       btnColor="#1F3F3C"
       isIcon={true}
       imageRight={true}
       bgImage={CraftingImage}
       />
        <div className={styles.visionToValue}>
      <div className={styles.visionToValueContent}>
      <div className={styles.visionToValueIntro}>
        <h2>From Vision to Value</h2>
        <p>From concept to code, we translate your ideas into software solutions that create a measurable value for your enterprise. </p>
        </div>
        <div className={styles.cardRow}>
        <Card
        title="Tailored Software Architecture"
        subtitle="We architect our AI solutions to be mission-specific, nimble, and aligned with your specific business requirements."
        icon={Icon1}
        />
        <Card
        title="Value-creative"
        subtitle="We develop our software alongside you with the intent to bring you a capability that creates immediate value for your business."
        icon={Icon2}
        />
        <Card
        title="Seamless Integration"
        subtitle="The AI software we develop integrates smoothly with your existing systems, ensuring a unified and cohesive technology ecosystem."
        icon={Icon3}
        />
      </div>
      </div>
      </div>
       <Process 
      title="Empowering Your Operations"
      checks={check} 
      bgColor='#35736B'
      txtColor='#FAFDFB'
      isTimeline={false}
      />
       <FullImage 
    title="Partner with Olivya to make your business smarter, faster, and more intuitive. 
    Take the Leap into AI"
    btnLabel="Let's Begin Your Journey"
    bgImg={CTABg}
    onClickButton={handleButtonClickNavigation}
    
    />


    </div>

  )
}

export default AiSoftware