import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import AiSoftware from './pages/AI Software/AiSoftware';
import AIConsulting from './pages/AI Consulting/AiConsulting';
import Payments from './pages/Payments/Payment';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Privacy from './pages/Legal/Privacy';
import Terms from './pages/Legal/Terms';
import { initializeGA, trackPageView } from './analytics'; // Adjust the path as necessary

// Helper component to use location hook in useEffect
const RouteChangeTracker = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);
};

function App() {
  useEffect(() => {
    initializeGA();
    trackPageView();
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <RouteChangeTracker /> {/* This component tracks page navigation */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ai-consulting" element={<AIConsulting />} />
          <Route path="/ai-software" element={<AiSoftware />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
