import React, { useState } from 'react';
import { RiMenu3Line as HamburgerMenu} from 'react-icons/ri';
import   {IoMdClose as CloseMenu } from 'react-icons/io';
import styles from './Header.module.scss';
import txtlogo from '../../assets/images/logo_olivya_txt.png';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const linkVariants = {
    hover: { scale: 1.1, fontWeight: 700 },
    pressed: { fontWeight: 700 },
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <a  href="/">
      <div className={styles.logo}>
        
        <img src={txtlogo} alt='Olivya logo' />
       
      </div>
      </a>

      <nav className={`${styles.navigation} ${isMenuOpen ? styles.show : ''}`}>
        <ul>

          <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="/"
            >
              Home
            </motion.a>


            <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="/ai-consulting"
            >
              AI Consulting
            </motion.a>
            <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="/ai-software"
            >
              AI Software
            </motion.a>
            <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="https://voice.olivya.io"
            >
              Voice Agent
            </motion.a>

            <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="/about"
            >
              About
            </motion.a>
            <motion.a
              variants={linkVariants}
              whileHover="hover"
              whileTap="pressed"
              href="/contact"
            >
              Contact
            </motion.a>
        </ul>
      </nav>

      <div className={styles.menuIcon} onClick={toggleMenu}>
        {isMenuOpen ? <CloseMenu /> : <HamburgerMenu />}
      </div>
    </header>
  );
};

export default Header;
