import React from 'react';
import { motion } from 'framer-motion';
import styles from './TitleSection.module.scss'
import logo from '../../assets/svg/olivya-logo.svg'; 

const TitleSection = ({ title, subtitle1, subtitle2, imageRight, headingtxtColor, txtColor }) => {
  const sectionStyles = {
    color: txtColor,
  };

  // Define animation variants
  const titleVariant = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const subtitleVariant = {
    offscreen: { opacity: 0, y: -10 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } },
  };

  const columnVariant = {
    offscreen: { opacity: 0, x: -10 },
    onscreen: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1 } },
  };

  return (
    <motion.section 
      className={styles.titleContainer} 
      style={sectionStyles}
      initial="offscreen"
      whileInView="onscreen"
    >
      <div className={styles.introContent}>
        {title && (
          <motion.h2
            style={{ color: headingtxtColor }}
            variants={titleVariant}
            initial="offscreen"
            whileInView="onscreen"
          >
            {title}
          </motion.h2>
        )}

        <div className={styles.subtitlesRow}>
          {subtitle1 && (
            <motion.p
              style={{ color: txtColor }}
              variants={subtitleVariant}
              initial="offscreen"
              whileInView="onscreen"
            >
              {subtitle1}
            </motion.p>
          )}
          {subtitle2 && (
            <motion.p
              style={{ color: txtColor }}
              variants={subtitleVariant}
              initial="offscreen"
              whileInView="onscreen"
            >
              {subtitle2}
            </motion.p>
          )}
        </div>
        <div className={imageRight ? styles.logoLeft :  styles.logoRight}>
    <img src={logo} alt="Logo" />
  </div>
      </div>
    </motion.section>
  );
};

export default TitleSection;
