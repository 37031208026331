import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Home.module.scss';
import Hero from '../../Components/Hero/Hero';
import Card from '../../Components/Card/Card';
import excludeIcon from '../../assets/images/exclude_icon.png';
import chartIcon from '../../assets/images/chart_icon.png';
import contactlessPaymentIcon from '../../assets/images/contactless_payment_icon.png';
import IconSection from '../../Components/Sections/IconSection';
import PbIcon1 from '../../assets/images/enhanced_profitability_icon.png';
import PbIcon2 from '../../assets/images/streamlined_operation_icon.png';
import PbIcon3 from '../../assets/images/informed_decision_icon.png';
import ImageSection from '../../Components/Sections/ImageSection';
import Checkbox from '../../assets/images/checkbox.png';
import AutomatingCorporatePaymentsImg from '../../assets/images/automating_corporate_payments.png'
import SolutionBg from '../../assets/images/solution_bg.png';
import FullImage from '../../Components/Sections/FullImage';
import CTABg from '../../assets/images/cta_bg.png';
import AiConsulting from '../../assets/images/ai_consulting.png'
import FooterImage from '../../Components/Sections/FooterImage';



export default function Home() {
//   const [scrollY, setScrollY] = useState(0);
//   const [contentScale, setContentScale] = useState(0);

//  useEffect(() => {
//     const handleScroll = () => {
//       const position = window.scrollY;
//       const windowHeight = window.innerHeight;

//       // Adjust the starting points and scale factors
//       const parallaxStart = windowHeight * 0.8;
//       const parallaxScale = 0.3;

//       if (position > parallaxStart) {
//         setScrollY(-(position - parallaxStart) * parallaxScale);
//       } else {
//         setScrollY(0);
//       }

//       const fullImageStart = windowHeight * 1.5;
//       if (position > fullImageStart) {
//         setContentScale(Math.min(1, (position - fullImageStart) / windowHeight));
//       } else {
//         setContentScale(0);
//       }
//     };

//     window.addEventListener('scroll', handleScroll, { passive: true });
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);


  const problemColumn = [
    {
      icon: PbIcon1,
      iconTitle: 'Efficiency',
      iconDescription: 'Improve efficiency by identifying workflow-improving AI applications'

      
    },
    {
      icon: PbIcon2,
      iconTitle: 'Generative',
      iconDescription: 'Enable your employees’ creativity and empower them with generative tools.'

    },
    {
      icon: PbIcon3,
      iconTitle: 'Predictive',
      iconDescription: 'Enhance strategic decision making by leveraging data and predictive modelling.  '
    },
  ];

  
  const navigate = useNavigate();

  const handleClickSubtitle = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Reset scroll position
};

  const handleButtonClickNavigation = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  const handleButtonClickAiConsulting = () => {
    navigate('/ai-consulting');
    window.scrollTo(0, 0);
  };

  const handleButtonClickPayments = () => {
    navigate('/payments');
    window.scrollTo(0, 0);
  };

 

  return (
    
    <div className={styles.home}>
      <section className={styles.heroSection}>
        <Hero />
        <div className={styles.cardRow}>
        
        <Card
         title="AI Software Development"
         subtitle="We develop mission-specific applications and software"
         icon={excludeIcon}
         subtitleLink='/ai-software'
         onClickSubtitle={() => handleClickSubtitle('/ai-software')}

          />
           <Card
         title="AI Consulting"
         subtitle="We can assist your business by identifying AI uses cases suited for your goals and help you action them"
         icon={chartIcon}
         subtitleLink='/ai-consulting'
         onClickSubtitle={() => handleClickSubtitle('/ai-consulting')}

          />
           <Card
  title="AI Voice Agent"
  subtitle={
    <a 
      href="https://voice.olivya.io" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      Fully customisable, AI-powered voice assistant that gives a whole new level of efficiency and personalisation to your inbound and outbound calls
    </a>
  }
  icon={contactlessPaymentIcon}
/>
          </div>
          </section>


         
          <IconSection
  title="Redefining Business with AI Integration"
  subtitle1="Integrating AI into the foundational operations of your company is a strategic move towards innovative leadership and a progressive business model. This fusion creates an interactive environment where artificial intelligence complements and elevates human capabilities, ensuring that each business function is re-envisioned to be smarter, more responsive, and adaptable to change."
  subtitle2="In the dynamic landscape of modern business, integrating AI is not just about staying competitive; it's about redefining what's possible.  Olivya stands at the forefront of this transformation, believing in a future where technology is not a  complexity, but a seamless extension of human capability. "
  columns={problemColumn}
  bgColor="#1F3F3C"
  txtColor="#8EA7A4"
  headingtxtColor="white"
/>


<FullImage
bgImg={SolutionBg}
title='How can we help?'
subtitle1='Olivya serves as the bridge to AI sophistication for businesses ready to evolve. Our services bring the necessary awareness and technical know-how, transforming complex technologies into accessible, impactful tools that streamline your processes.'
btnLabel='Contact us'
txtColor='#1F3F3C'
btnColor='#1F3F3C'
onClickButton={handleButtonClickNavigation}
// scale={contentScale}

/>



<ImageSection
imageRight={false}
isList={true}
isIcon={true}
isbglogo={true}
title="AI Consulting and Implementation"
btnLabel='Learn More'
bgImage={AiConsulting}
bgColor='#35736B'
headerTxtColor='#FFFFFF'
txtColor='#FFFFFF'
btnColor='#1F3F3C'
listItems={[
  { icon: Checkbox, text: 'Executive Education : Comprehensive brief of AI applications and potential in you sector' },
  { icon: Checkbox, text: 'Assessment of Operations : Tailored insights into enhancing your business processes' },
  { icon: Checkbox, text: 'Bespoke AI Use Cases & Roadmaps: Discover and deploy AI that revolutionises your workflows' },
]}
onClickButton={handleButtonClickAiConsulting}
/>

<ImageSection
  imageRight={true}
  isList={true}
  isIcon={true}
  title="AI Phone Agent - Give your Brand a Voice"
  btnLabel='Learn More'
  bgImage={AutomatingCorporatePaymentsImg}
  bgColor='#35736B'
  headerTxtColor='#FFFFFF'
  txtColor='#FFFFFF'
  btnColor='#1F3F3C'
  listItems={[
    { icon: Checkbox, text: 'Increase your Capacity and Turnover: Boost customer loyalty and engagement with 24/7 coverage' },
    { icon: Checkbox, text: 'Resolve Customer Queries with Smart Actions: The agent will be able to execute and resolve on the fly' },
    { icon: Checkbox, text: 'Deepen your Customer Insight: Capture the true tone and feeling from interactions customers' },
  ]}
  onClickButton={() => window.location.href = 'https://voice.olivya.io'}
/>


{/* <TestimonialSection /> */}

<FooterImage 
title="Ready to transform your business with technology that understands you?"
subtitle1="Elevate Your Business with Tailored AI Insights. Contact Us for a Consultation"
btnLabel="Let's Begin Your Journey"
txtColor='#1F3F3C'
btnColor='#1F3F3C'
bgColor='#FAFDFB'
imageRight={false}
onClickButton={handleButtonClickNavigation}
/>
</div>


  )
}
