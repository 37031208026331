import React, { useState } from 'react';
import styles from './Process.module.scss';
import { FaCheck, FaChevronDown } from 'react-icons/fa';
import Check from '../Check/Check';
import logo from '../../assets/svg/olivya-logo.svg'; 

const Process = ({ title, subtitle, steps, bgColor, imageRight, txtColor, isTimeline, checks }) => {
  const sectionStyles = {
    backgroundColor: bgColor,
    color: txtColor,
  };

  const [activeStep, setActiveStep] = useState(null);

  const toggleStep = (index) => {
    setActiveStep(activeStep === index ? null : index);
  };

  return (
    <div className={styles.process} style={{ ...sectionStyles }}>
      <div className={styles.contentContainer}>
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
        <div className={styles.flexContainer}> {/* New flex container */}
          <div className={styles.steps}>
            {isTimeline ? (
              steps.map((step, index) => (
                <div key={index} className={`${styles.step} ${activeStep === index ? styles.active : ''}`} onClick={() => toggleStep(index)}>
                  <div className={styles.stepHeader}>
                    <div className={styles.stepNumber}>
                      <p>{index + 1}</p>
                    </div>
                    <p className={styles.stepTitle}>{step.title}</p>
                    <div className={styles.stepArrow}>
                      <FaChevronDown />
                    </div>
                  </div>
                  {activeStep === index && <div className={styles.stepDetail}><p>{step.detail}</p></div>}
                </div>
              ))
            ) : (
              checks.map((check, index) => (
                <Check key={index} title={check.title} text={check.text} />
              ))
            )}
          </div>
          <div className={imageRight ? styles.logoRight : styles.logoLeft}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
