import React from 'react';
import styles from './Check.module.scss';
import check from '../../assets/images/checkbox.png'

const StepRow = ({ title, text }) => {
  const iconSize = '1em'; // Set the icon size here

  return (
    <div className={styles.stepRow}>
      <div className={styles.checkIcon}>
      <img src={check} alt='List icon'/>
      </div>
      <div className={styles.checkContent}>
      {title && <p><strong>{title}</strong></p>}
      <p>{text}</p>
      </div>
    </div>
  );
};

export default StepRow;
