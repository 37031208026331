import React from 'react'

import styles from './AIConsulting.module.scss'
import ImageSection from '../../Components/Sections/ImageSection';
import IconSection from '../../Components/Sections/IconSection';
import Process from '../../Components/Sections/Process'
import FullImage from '../../Components/Sections/FullImage'
import WorkIcon1 from '../../assets/images/workIcon1.png';
import WorkIcon2 from '../../assets/images/workIcon2.png';
import WorkIcon3 from '../../assets/images/workIcon3.png';
import CaseStudy from '../../Components/CaseStudy/CaseStudy';
import CuttingEdgeTech from '../../assets/images/cutting-edge-tech.png';
import AiLeap from  '../../assets/images/ai-leap.png'
import TitleSection from '../../Components/Sections/TitleSection';

import FutureAccelerated from '../../assets/images/future-accelerated.png';


function AIConsulting() {

  const workColumn = [
    {
      icon: WorkIcon1,
      iconTitle: 'Bespoke Solutions',
      iconDescription: "We don't just offer AI; we tailor it to fit your unique needs."
      
    },
    {
      icon: WorkIcon2,
      iconTitle: 'Minimal Disruption',
      iconDescription: 'Our methodology ensures a smooth transition to smarter processes.'
    },
    {
      icon: WorkIcon3,
      iconTitle: 'Fresh Perspective',
      iconDescription: 'We bring a range of skills that help our clients ideate beyond technology '
    },
  ];

  const steps = [
    { title: 'Executive Education', 
    detail: 'We brief the executive team on AI’s potential in your industry and give the team an overview of current AI applications affecting the field. Our goal is to give you the tools and knowledge to be able to continuously identify ways of weaving artificial intelligence into your processes.' },

    { title: 'Assessment of Current Processes', 
    detail: 'We delve into your operations to understand your workflows and identify areas where AI can make a significant difference.' },

    { title: 'Identification of AI Use Cases', 
    detail: 'We identify and present a set of specific use cases that can be used within your organisation. We clearly outline the benefits of each and explain the technologies that would be used.' },
    { title: 'Roadmap for Implementation', 
    detail: 'For the use cases that we have selected and prioritised together, We will give you a roadmap for implementation or develop them for you.' },
 
  ];




  return (
    <div className={styles.aiConsulting}>
      <TitleSection
title="Empower Your Business with Cutting-Edge AI Solutions"
subtext='Transformative Technology, Tailored for You'
subtitle2='Transformative Technology, Tailored for You. At Olivya, we bring the future of artificial intelligence to your doorstep, transforming complex systems into intuitive solutions that elevate your business operations.

Our AI Consultancy services are designed to help you harness the power of AI and turn it into your competitive advantage.'
bgColor='#35736B'
txtColor='#FAFDFB'


/>

    <Process 
      title="Here's Our Approach"
      steps={steps}
      bgColor='#1F3F3C'
      txtColor='#EEF6F5'
      isTimeline={true}
      imageRight={true}
      />
    <IconSection 
      columns={workColumn}
    title='Why work with us?'
    subtitle1="We understand that each business is as unique as the challenges it faces. That's why we don't deal in one-size-fits-all solutions; we specialise in bespoke AI strategies meticulously crafted to meet your individual objectives. 

    Our personalised approach is designed to integrate advanced AI into your workflow, maintaining the essence of your business while elevating its capabilities. "
    subtitle2="Our engineers are specialists in the field and take pride in leading innovation with every project. Their deep expertise and pioneering spirit are real assets to Olivya and its clients. "
    bgColor='#35736B'
    txtColor='#FAFDFB'
    
    />

<ImageSection
      title="Your Future, Accelerated"
      subtitle1="Olivya's AI software is not just about upgrading technology—it's about upgrading your entire business paradigm. From the initial concept to the final deployment, we are committed to transforming your vision into reality with cutting-edge software."
      subtitle2="We want to help you foster a can-do work culture centred around ambition and optimism."
      btnLabel="Embark on the AI Journey"
      bgColor='#FAFDFB'
      txtColor='#1F3F3C'
      btnColor="#1F3F3C"
      isIcon={true}
      bgImage={FutureAccelerated}
      />

       
    </div>
  )
}

export default AIConsulting