import React, { useState } from 'react';
import styles from './CustomButton.module.scss';

const CustomButton = ({ label, bgColor, txtColor, onClick }) => {
  const [hover, setHover] = useState(false);
  const btnStyles = {
    backgroundColor: hover ? '#CC745D' : bgColor,
    color: txtColor,
  };

  return (
    <button 
      className={styles.btn} 
      style={btnStyles}
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
      onClick={onClick} // Use the onClick prop here
    >
      {label}
    </button>
  );
};

export default CustomButton;

