import React from 'react';
import { motion } from 'framer-motion';
import logo from '../../assets/svg/olivya-logo.svg'; 
import styles from './Hero.module.scss';
import { Link } from 'react-router-dom';

export default function Hero() {

  // Variants for the text and buttons
  const textVariants = {
    offscreen: { y: 30, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  return (
    <div className={styles.heroBackground}>
      <motion.div
        className={styles.animatedLogo}
        style={{ backgroundImage: `url(${logo})` }}
        animate={{ rotate: [0, 360] }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear"
        }}
      />

      <div className={styles.heroContent}>
        <div className={styles.heroContentInner}>
          <motion.h1
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
          >
            Empowering Human Aspirations with AI Innovation
          </motion.h1>
          <motion.p
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            transition={{ delay: 0.2 }}
          >
            We are committed to delivering technology as a powerful, intuitive tool that enables human ingenuity and creativity towards purposeful endeavours.
          </motion.p>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={textVariants}
            transition={{ delay: 0.4 }}
          >
            <div className={styles.buttonRow}>
      <Link to="/contact">
        <button className={styles.mainCta}>Connect with us</button>
      </Link>
     
    </div>
  
          </motion.div>
        </div>
      </div>
    </div>
  );
}
