import React from 'react';
import { motion } from 'framer-motion';
import styles from './Card.module.scss';

export default function Card({ title, subtitle, icon, subtitleLink, onClickSubtitle }) { // Add onClickSubtitle prop
    return (
        <motion.div 
          className={styles.cardContainer}
          whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
        >
            <div className={styles.cardContent}>
              <h6>{title}</h6>
              <p>
                {subtitleLink 
                  ? <span style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} onClick={onClickSubtitle}>{subtitle}</span> // Use a span with onClick
                  : subtitle}
              </p>
              <img src={icon} alt="Icon" />
            </div>
        </motion.div>
    );
}
