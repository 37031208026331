import React, { useState, useRef } from 'react';
import styles from './Contact.module.scss';
import { motion } from 'framer-motion';
import { CiPhone, CiMail, CiLocationOn } from 'react-icons/ci';
import emailjs from '@emailjs/browser';
import CustomButton2 from '../../Components/Button/CustomButton2';
import logo from '../../assets/svg/olivya-logo.svg';

function Contact() {
  const formRef = useRef();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.firstName.trim()) {
      errors.firstName = "Please enter your first name.";
      formIsValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Please enter your last name.";
      formIsValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = formData.email.trim() ? "Invalid email format. Please enter a valid email address." : "Please enter your email address.";
      formIsValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = "Please enter your phone number.";
      formIsValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = "Please enter your message.";
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    emailjs
      .sendForm('service_3ibf6ze', 'template_nndaal8', formRef.current, 'qfXbHvcHItvIp1QXo')
      .then(
        (result) => {
          console.log(result.text);
          // Optionally, reset form or give success message
          alert('Thank you for your message! We will get back to you shortly.');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className={styles.contact}> 
      <div className={styles.contactForm}>
        <div className={styles.information}>
          <h5>Contact us</h5>
          <p>Contact us to start your journey towards AI-driven success.</p>
          <div className={styles.iconRowContainer}>
            <motion.div
              className={styles.animatedLogo}
              animate={{ rotate: [0, 360] }}
              transition={{ duration: 20, repeat: Infinity, ease: 'linear' }}
            >
              <img src={logo} alt="Olivya Logo" />
            </motion.div>
          </div>
        </div>
        <div className={styles.formContent}>
          <CustomButton2 label="Book a consultation with us" bgColor="#CC745D" type="Book" onClick={() => window.location.href="https://calendly.com/olivya_ryan/30min"}/>
    <div style={{ height: '30px' }}></div>
    <p style={{ marginBottom: '20px' , textAlign: 'center'}}>OR</p>
        <p style={{ marginBottom: '5px' }}>Submit a request:</p>

 
  <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
  
  <form ref={formRef} onSubmit={handleSendEmail}>
    <div className={styles.inputRow}>
      <input type="text" name="firstName" placeholder="First Name" onChange={handleInputChange} />
      <input type="text" name="lastName" placeholder="Last Name" onChange={handleInputChange} />
    </div>
    <div className={styles.inputRow}>
      <input type="email" name="email" placeholder="Email Address" onChange={handleInputChange} />
      <input type="tel" name="phone" placeholder="Phone Number" onChange={handleInputChange} />
    </div>
    <textarea name="message" placeholder="Your Requirements" onChange={handleInputChange}></textarea>
    <CustomButton2 label="Submit" bgColor="#1F3F3C" type="submit" />
  </form>
</div>
      </div>
    </div>
  );
}

export default Contact;
