import React from 'react';
import { motion } from 'framer-motion';
import styles from './IconSection.module.scss';

const IconSection = ({ title, subtitle1, subtitle2, columns, bgColor, headingtxtColor, txtColor }) => {
  const sectionStyles = {
    background: bgColor,
    color: txtColor,
  };

  // Define animation variants
  const titleVariant = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const subtitleVariant = {
    offscreen: { opacity: 0, y: -10 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } },
  };

  const columnVariant = {
    offscreen: { opacity: 0, x: -10 },
    onscreen: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 1 } },
  };

  return (
    <motion.section 
      className={styles.sectionContainer} 
      style={sectionStyles}
      initial="offscreen"
      whileInView="onscreen"
    >
      <div>
        {title && (
          <motion.h2
            style={{ color: headingtxtColor }}
            variants={titleVariant}
            initial="offscreen"
            whileInView="onscreen"
          >
            {title}
          </motion.h2>
        )}

        <div className={styles.subtitlesRow}>
          {subtitle1 && (
            <motion.p
              style={{ color: txtColor }}
              variants={subtitleVariant}
              initial="offscreen"
              whileInView="onscreen"
            >
              {subtitle1}
            </motion.p>
          )}
          {subtitle2 && (
            <motion.p
              style={{ color: txtColor }}
              variants={subtitleVariant}
              initial="offscreen"
              whileInView="onscreen"
            >
              {subtitle2}
            </motion.p>
          )}
        </div>

        <div className={styles.iconRow}>
          {columns.map((column, index) => (
            <motion.div 
              key={index} 
              className={styles.column} 
              variants={columnVariant}
              initial="offscreen"
              whileInView="onscreen"
            >
              {column.icon && (
                typeof column.icon === 'string' ? 
                <img src={column.icon} alt="Icon" className={styles.icon} /> : 
                column.icon
              )}
              {column.iconTitle && <h6 className={styles.iconTitle} style={{ color: headingtxtColor }}>{column.iconTitle}</h6>}
              {column.iconDescription && <p className={styles.iconDescriptionText}>{column.iconDescription}</p>}
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default IconSection;
