import React from 'react';
import { motion } from 'framer-motion';
import styles from './FullImage.module.scss';
import CustomButton from '../Button/CustomButton';

const FullImage = ({ bgImg, title, subtext, subtitle1, subtitle2, txtColor, quote, name, btnColor, btnTextColor, onClickButton, btnLabel, isCTA, height }) => {
  const sectionStyles = {
    color: txtColor,
    height: `${height}px`, // Apply dynamic height
  };

  // Animation for fade in
  const contentVariant = {
    offscreen: { opacity: 0 },
    onscreen: { 
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.2 
      }
    },
  };

  return (
    <section className={styles.sectionContainer} style={sectionStyles}>
      <div className={styles.fullImageContainer} style={{ backgroundImage: `url(${bgImg})` }}>
        <motion.div 
          className={styles.content} 
          initial="offscreen" 
          whileInView="onscreen" 
          variants={contentVariant}
        >
          {/* Content goes here */}
          {title && (isCTA ? <h5>{title}</h5> : <h3>{title}</h3>)}
          {subtext && <p><strong>{subtext}</strong></p>}
          {subtitle1 && <p>{subtitle1}</p>}
          {subtitle2 && <p>{subtitle2}</p>}
          {quote && <p>{quote}</p>}
          {name && <p>{name}</p>}
          {btnLabel && <CustomButton label={btnLabel} bgColor={btnColor} onClick={onClickButton} />}
        </motion.div>
      </div>
    </section>
  );
}

export default FullImage;
